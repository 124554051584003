import {
  faClock,
  faEnvelopeSquare,
  faPhoneSquare
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import SEO from "../components/seo";

function ContactPage({ location }) {
  return (
    <>
      <SEO
        title="Contact"
        description="Contact Paula via email, phone or text to learn more about our adult and children classes and private lessons!"
        keywords={[`contact`, `bath`, `dancentre`, `email`, `phone`, `address`]}
        location={location}
      />
      <section className="flex flex-col items-center">
        <div className="max-w-lg w-auto mx-auto md:p-10 border-primary md:border">
          <h2>Postal Address</h2>
          <span className="font-semibold">The Bath Dancentre</span>
          <br />
          The Fold
          <br />
          Englishcombe Village
          <br />
          Bath
          <br /> BA2 9DU
          <h2 className="mt-2">Email</h2>
          <div>
            <FontAwesomeIcon icon={faEnvelopeSquare} size="lg" />
            <a
              className="ml-2 underline text-highlight"
              href="mailto:paulagoodyear@btinternet.com"
            >
              paulagoodyear@btinternet.com
            </a>
          </div>
          <h2 className="mt-2">Phone</h2>
          <div>
            <div>
              <FontAwesomeIcon icon={faPhoneSquare} size="lg" />
              <a className="ml-2" href="tel:+447860 839560">
                07860 839560
              </a>
            </div>
            <div>
              <FontAwesomeIcon icon={faClock} size="lg" />
              <p className="inline ml-2">Monday - Sunday (9:00am - 10:00pm).</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactPage;
